@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.leaderboard__page{
    box-sizing: border-box;
}

.leaderboard__header {
    position: relative;
    margin-top: -10vh;
    background-image: url('../../assets/images/background7-min.jpg');
    background-position: center;
    background-size: cover;
    height: 40vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to bottom,
            rgba(58, 69, 65, 0.8), /* Medium-Green */
            rgba(19, 28, 30, 0.7) /* Almost-Black */
        );
        z-index: 1;
    }
}

.leaderboard__title {
    position: relative;
    z-index: 2;
    color: $Light-Gray; /* Light-Gray text for visibility */
    font-size: clamp(1.5rem, 2.5vw, 3rem);
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
    padding: 0.75rem 1.5rem;
    background-color: $Dark-Green; /* Cohesive with your palette */
    border-radius: 12px;
    font-weight: bold;
    letter-spacing: 1.2px;
}


.leaderboard__section{
    margin: 1rem;
}


.scorecards__section{
    margin: 1rem;
}

@include tablet{
    .leaderboard__page{
        box-sizing: border-box;
        margin: 0 2rem;
    }
}

@include desktop{
    .leaderboard__page{
        box-sizing: border-box;
        margin: 1rem 3rem;
    }
}
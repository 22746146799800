@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.input__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.header__image{
    height: 50vh;
    width: 100%;
    margin: 0;
    padding: 0;
    background-image: url('../../assets/images/background3-min.png');
    background-position: center;
    background-size: cover;
    margin-top: -10rem;
}

.score{
    max-width: 375px;
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    margin-top: -8rem;


    &__info{
        box-sizing: border-box;
        width: 100%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        padding: 1rem 0;
        text-align: center;
        background-color: $primary-green;
        border: none;
        border-radius: 1rem;
        border-left: #DCD5B9 1px solid;
        border-top: #DCD5B9 1px solid;
        border-right: #DCD5B9 1px solid;
    }

        &__course{
            @include mobile-header-four;
            color: $primary-white;
            margin: 0;
        }

        &__hole{
            width: 100%;
            margin: 0;
            @include header-two;
            color: $primary-white;
        }

        &__nums{
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            @include mobile-body-one;
            color: $secondary-white;
        }


        &__par{
            width: 100%;
            margin: 0;
        }

        &__distance{
            width: 100%;
            margin: 0;
        }

    &__tracker{
        width: 85%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] {
            text-align: center;
        }

        &__decrement{
            border-radius: 50%;
            border: 1px solid #d3d3d3;
            height: 4rem;
            width: 4rem;
            transition: 0.2s ease-in;
            cursor: pointer;

            &:active{
                background-color: #000;
                color: #fff;
            }
        }

        &__increment{
            border-radius: 50%;
            border: 1px solid #d3d3d3;
            height: 4rem;
            width: 4rem;
            transition: 0.2s ease-in;
            cursor: pointer;

            &:active{
                background-color: #000;
                color: #fff;
            }
        }

        &__input{
            padding: 0.75rem 1rem;
            width: 3rem;
            height: 5rem;
            @include body-one;
        }

    &__control{
        width: 100%;
        height: 40%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        &__view{
            margin-bottom: 1rem;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        &__skip{
            padding: 0.75rem 1.5rem;
            font-size: 1rem;
        }

    &__previous{
        @include white-button;
    }

    &__next{
        @include white-button;
    }

    &__save{
        @include white-button;
        border: 1px solid $Light-Gray;
    }
}
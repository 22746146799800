@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    &__header{
        box-sizing: border-box;
        width: 100%;
        margin-top: 0;
        margin-bottom: 2rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        &--title{
            color: #fff;
            @include mobile-header-two;
        }

        &--link{
            text-decoration: none;
            color: #fff;
            @include mobile-header-three;
        }
    }
    

    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__text{
        @include mobile-body-three;
        color: $primary-white;

        a{
            @include mobile-header-three;
            color: $primary-white;
        }
    }
}

.form{
    display: flex;
    width: 70%;
    max-width: 550px;
    height: 50%;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(11.5px);

    &__image{
        width: 5rem;
    }

    &__login{
        padding: 2rem 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__title{
        @include mobile-header-one;
        color: $primary-white;
    }

    &__label{
        margin-bottom: 0.75rem;
    }
    
    &__button{
        margin: 1rem 0;
        @include white-button;
    }
}

    .password__input{
       @include input-field;
    }

    .email__input{
        @include input-field;
    }


@include tablet{
    .login{

        &__container{
            width: 60%;
            height: 70%;
            margin: 2rem auto;
            border-radius: 1rem;
        }

        &__text{
            @include body-three;

            a{
                @include header-four;
                color: $primary-white;
            }
        }
    }

    .form{

        &__title{
            @include header-two;
        }
    }
}
@use './typograghy' as *;
@use './variables.scss' as *;


// Breakpoint mixins
    @mixin tablet {
        @media (min-width: $tablet-breakpoint) {
            @content;
        }
    }

    @mixin desktop {
        @media (min-width: $desktop-breakpoint) {
            @content;
        }    
    }

// Fonts
    // Mobile Fonts
    @mixin mobile-header-one{
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    @mixin mobile-header-two{
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    @mixin mobile-header-three{
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    @mixin mobile-header-four{
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    @mixin mobile-header-five{
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    @mixin mobile-body-one{
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    @mixin mobile-body-two{
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    @mixin mobile-body-three{
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    @mixin mobile-body-four{
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    @mixin mobile-button-text{
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    // Desktop Fonts
    @mixin header-one{
        font-family: "Poppins", sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    @mixin header-two{
        font-family: "Poppins", sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    @mixin header-three{
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    @mixin header-four{
        font-family: "Poppins", sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    @mixin header-five{
        font-family: "Poppins", sans-serif;
        font-size: 0.75;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
    }

    @mixin body-one{
        font-family: "Poppins", sans-serif;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    @mixin body-two{
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    @mixin body-three{
        font-family: "Poppins", sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    @mixin body-four{
        font-family: "Poppins", sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    @mixin button-text{
        font-family: "Poppins", sans-serif;
        font-size: 1.15rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 3px;
        text-transform: uppercase;
    }



// Input Form Field styling
    @mixin input-field {
        box-sizing: border-box;
        overflow: none;
        width: 100%;
        padding: 0.5rem 0.75rem;
        border-radius: 0.3rem;
        transition: 0.2s;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background-color: rgba(255, 255, 255, 0.40);
        outline: none;

        &::placeholder{
            color: #d3d3d3;
        }

        &:focus{
            background-color: $primary-white;
            opacity: 0.9;
            color: $primary-black;
        }

        &:focus::placeholder{
            color: transparent;
        }
    }

// Box Shadow Mixin
    @mixin box-shadow{
        box-shadow: 11px 9px 25px -9px #9C9C9C;
    }


// Button Styling

    @mixin white-button{
        height: 3rem;
        width: 12rem;
        border-radius: 6rem;
        border: none;
        padding: 0.5rem 1.5rem;
        justify-content: center;
        align-items: center;
        background-color: $Olive-Green;
        border: 3px solid $Grayish-Green;
        color: $primary-white;
        gap: 1rem;
        @include button-text;
        cursor: pointer;
        transition: 0.25s;

        &:hover{
            background-color: $Grayish-Green;
            color: $Dark-Green;
        }
    }


    @mixin white-button-mobile{
        height: 3rem;
        width: 100%;
        padding: 0.5rem 1.5rem;
        border-radius: 6rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: $Olive-Green;
        border: 3px solid $Grayish-Green;
        color: $primary-white;
        @include mobile-button-text;
        cursor: pointer;
        transition: 0.25s;

        &:hover{
            background-color: $Grayish-Green;
            color: $Dark-Green;
        }
    }

    @mixin secondary-button{
        height: 3rem;
        width: 12rem;
        padding: 0.5rem 1.5rem;
        border-radius: 1.5rem;
        border: $primary-black 1px solid;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: transparent;
        @include mobile-button-text;
        cursor: pointer;
    }

    @mixin secondary-button-mobile{
        height: 3rem;
        width: 100%;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        background-color: transparent;
        @include mobile-button-text;
        cursor: pointer;
    }
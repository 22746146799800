@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.register{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    &__button{
        margin: 1rem 0;
        @include white-button;
    }
}

.left{
    display: none;
    width: 50%;
    height: 100%;
}

.form{
    display: flex;
    width: 80%;
    height: 70vh;
    padding: 32px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(11.5px);

    &__image{
        width: 8rem;
        z-index: 1000000;
    }

    &__register{
        padding: 1rem 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow: scroll;
    }

    &__input{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: start;

        &--errors{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__label{
        margin: 0.75rem 0;
        @include mobile-header-three;
        color: $primary-white;
    }

}

.error-message{
    color: red;
    font-weight: 600;
}

    .password__input{
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background-color: transparent;
        height: 40px;
        width: 100%;
        padding: 0px 12px;
        align-items: center;
        align-self: stretch;
        @include mobile-body-two;
        color: $primary-grey;
    }

    .email__input,
        .first__name__input,
            .last__name__input,
                .gender__input,
                    .birthday__input,
                        .city__input,
                            .province__input,
                                .country__input{
                                    border-radius: 4px;
                                    border: 1px solid rgba(255, 255, 255, 0.40);
                                    background-color: transparent;
                                    display: flex;
                                    height: 40px;
                                    width: 100%;
                                    padding: 0px 12px;
                                    align-items: center;
                                    align-self: stretch;
                                    @include mobile-body-two;
                                    color: rgba(255, 255, 255, 0.70);
    }

        .first__name__input,
            .last__name__input,
                        .city__input,
                            .province__input,
                                .country__input{
                                    text-transform: capitalize;
                                }


@include tablet {
    .register{
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &__container{
            width: 60%;
            height: 95%;
            margin: 2rem auto;
            border-radius: 1rem;
        }
    }

    .form{
        height: 70vh;

        &__input{
            width: 50%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: start;
        }
    }
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal__content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh; // Restrict height
    overflow-y: auto; // Enable scroll for overflow
  }
  
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .form__input--checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input[type="checkbox"] {
        margin-right: 1.5rem; // Space between checkbox and label
    }

    .terms__link {
        color: #1a0dab;
        text-decoration: underline;
        cursor: pointer;
    }
}
@font-face {
    font-family: "Poppins", sans-serif;
    src: url("../../assets/fonts/Poppins/Poppins-Light.ttf") format("ttf");
    font-weight: 200;
}

@font-face {
    font-family: "Poppins", sans-serif;
    src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins", sans-serif;
    src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins", sans-serif;
    src: url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("ttf");
    font-weight: 700;
}
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.drop-header{
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    z-index: 9999 !important;


    &__nav-desktop{
        display: none;
    }

    &__title{
        @include mobile-header-three;
        color: $primary-white;
    }

    &__nav-mobile{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        border-radius: 12px;
        background-color: #000;
        // background: rgba(0, 0, 0, 0.50);
        // backdrop-filter: blur(6px); 
        width: 90%;
        padding: 0 1rem;
        z-index: 9999 !important;
    }

    &__container{
        position: relative;
        display: inline-block;

        ul{
            margin: 0;
            padding: 0;
        }
    }

    &__button{
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid #d3d3d3;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem;
    }

        &__item{
            list-style: none;
            padding-left: 1rem;
            transition: 0.2s;

            &:hover{
                background-color: #d3d3d3;
            }
        }

        &__link{
            height: 1rem;
            padding: 0.5rem 0;
            margin: 0;
            text-decoration: none;
            display: block;
            width: 100%;
            text-align: start;
            @include mobile-body-two;
            color: $primary-black;
            z-index: 10000;
        }
}

.hide{
    display: none;
}

.show{
    box-sizing: border-box;
    display: block;
    position: absolute;
    z-index: 15;
    align-items: center;
    right: 0.30rem;
    background-color: #f1f1f1;
    width: 10rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10000 !important;
    padding: 0;
}



@include tablet{
    .drop-header{
        padding: 0 1rem;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &__title{
            color: $primary-white;
            @include header-three;
            line-height: normal;
        }
    
    
        &__nav-desktop{
            width: 90%;
            max-width: $desktop-breakpoint;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            border-radius: 12px;
            background-color: #000;
            // background: rgba(0, 0, 0, 0.50);
            // backdrop-filter: blur(6px); 
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 3rem;
            z-index: 9999 !important;
        }
    
        &__nav-mobile{
            display: none;
        }

        &__links-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: auto;
        }

        &__link{
            margin-left: 2rem;
            text-wrap: nowrap;
            border-radius: 1rem;
            color: #000;
            padding: 0.5rem 0.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10rem;
            transition: 0.2s;
            @include body-four;
            color: $primary-white;

            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__show-more{
            border: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 100%;

            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &__item{
            list-style: none;
            padding-left: 1rem;
            transition: 0.2s;
            background-color: $secondary-grey;
            color: $primary-black;

            &:hover{
                background-color: #d3d3d3;
            }
        }

        &__link{
            height: 1rem;
            padding: 0.5rem 0;
            margin: 0;
            text-decoration: none;
            display: block;
            width: 100%;
            text-align: start;
            margin-left: 2rem;
        }
    }

    .show-more__letter{
        height: 2rem;
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include body-three;
        color: $primary-white;
    }
}

@include desktop{
    .drop-header{
        padding: 0 1rem;
        width: 100%;
        box-sizing: border-box;
    
    
        &__nav-desktop{
            max-width: $desktop-breakpoint;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            
        }

        &__item{
            // background: rgba(0, 0, 0, 0.50);
            // backdrop-filter: blur(6px); 
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
.tournament--progress {
  &__container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include header-two;
    color: $Olive-Green;
    text-align: center;
    line-height: 1.25;
  }

  &__card {
    border: 1px solid #000;
    width: 80%;
    padding: 1rem;
    background-color: $Light-Gray;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);

    &-subtitle {
      @include mobile-header-two;
      text-align: center;
      color: $Olive-Green;
    }
  }

  &__course-list {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
  }

  &__course {
    padding: 0.5rem 0;
    color: $Almost-Black;
  }

  &__score{
    @include mobile-header-three;
  }

  &__summary {
    margin-top: 1rem;
    padding: 0.25rem 1rem;
    background-color: $Medium-Green;
    border-radius: 0.5rem;
    color: $Light-Gray;
    text-align: center;
  }

  &__total-score{
    font-size: 1.25rem;
    margin: 0.5rem auto;
  }
}

.tournament--progress__graphic {
  background-color: #f1f1f1;
  border: 1px solid #000; /* Light background to make it visible */
  height: 20px; /* Ensure this has height */
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.tournament--progress__bar {
  background-color: #3b7a57; /* Medium-Green (ensure this color is applied) */
  height: 100%; /* Fill the height of the container */
  border-radius: 10px;
  transition: width 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
}
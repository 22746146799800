@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;


.about-page {
  width: 100vw;
  box-sizing: border-box;
}

// Header Section Styling
.header {
  box-sizing: border-box;
  background-color: $Olive-Green;

  &__nav {
    box-sizing: border-box;
    margin: 0 1rem;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--subtitle {
      @include mobile-header-three;
    }

    &--link {
      color: $primary-white;
      text-decoration: none;
      letter-spacing: 1px;
    }
  }

  &__hero {
    background-image: url('../../assets/images/about-header.jpeg');
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.5rem 0;

    &--container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 90%;
      background-color: $Olive-Green;
      border-radius: 3rem;
    }
  }

  &__hero--title {
    @include mobile-header-one;
    color: $primary-white;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }

  .hero__container--section {
    padding: 0;
  }

  .hero__container--link {
    text-decoration: none;
    color: $primary-white;
    @include mobile-header-four;
    letter-spacing: 1px;

    &:hover{
      text-decoration: underline 2px $Grayish-Green;
    }
  }
}

// Overview Section Styling
.overview {
  box-sizing: border-box;
  margin-top: 0;

  &__divide {
    width: 100vw;
    background-color: $Olive-Green;
    height: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white;

    &--title {
      @include mobile-header-two;
      line-height: 1;
      padding: 0rem 0;
    }
  }

  &__paragraph {
    margin: 0.5rem 1rem;

    &--text {
      line-height: 1.75;
      margin: 1.25rem 0;
    }
  }
}

// Features Section Styling
.features {
  box-sizing: border-box;

  &__divide {
    width: 100vw;
    background-color: $Olive-Green;
    height: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white;

    &--title {
      @include mobile-header-two;
      line-height: 1;
      padding: 0rem 0;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1rem 1rem 2rem;
  }
}

.feature-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  width: 200px;

  &__icon {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    color: #666;
  }
}

// Courses Section Styling
.courses {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__divide {
    width: 100vw;
    background-color: $Olive-Green;
    height: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white;

    &--title {
      @include mobile-header-two;
      line-height: 1;
      padding: 0rem 0;
    }
  }

  &__container {
    margin: 1rem;
  }

  &__city {
    cursor: pointer;
    margin-bottom: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);

    &:hover {
      background-color: #e9e9e9;
    }

    &-name {
      font-size: 18px;
      color: #34495e;
    }
  }

  &list--container {
    list-style: none;
    padding: 0;
  }

  &list--name {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }
  }
}

// Mission Section Styling
.mission {
  box-sizing: border-box;

  &__divide {
    width: 100vw;
    background-color: $Olive-Green;
    height: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white;

    &--title {
      @include mobile-header-two;
      line-height: 1;
      padding: 0rem 0;
    }
  }

  &__paragraph {
    margin: 0.75rem 1rem;
    line-height: 1.75;
  }
}

// Footer Section Styling
.footer {
  box-sizing: border-box;

  &__links {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    .footer__link--item {
      margin: 0 10px;
      color: #000;
      text-decoration: none;
    }
  }
}

// Tablet Screen Size Styling
@include tablet {
  .header {
    &__hero {
      margin: 0.5rem 0;
      padding: 3.5rem 0;
      background-position: center;
      background-size: cover;

      &--title {
        margin-bottom: 4rem;
        @include mobile-header-one;
      }

      &--container {
        max-width: 600px; /* Adjust for tablet */
      }
    }

    &__nav {
      margin: 0 2rem;
    }
  }

  .overview {
    box-sizing: border-box;

    &__paragraph {
      margin: 0.75rem 2rem;
      line-height: 2;
    }
  }

  .courses {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      box-sizing: border-box;
      width: 90%;
      margin: 0.75rem 2rem;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .mission {
    box-sizing: border-box;

    &__paragraph {
      margin: 0.75rem 2rem;
      line-height: 2;
    }
  }
}

@include desktop {
  .header {
    &__hero {
      margin: 2rem 0;
      padding: 4rem 0;
      background-position: center;
      background-size: cover;

      &--title {
        @include header-one;
      }

      &--container {
        max-width: 800px; /* Adjust for desktop */
      }
    }

    &__nav {
      margin: 0 3rem;
      display: flex;
      align-items: center;
    }
  }
}
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.group-scorecard__container{
    padding: 1rem 1rem;
}

.group-scorecard{
    width: 100%;
    margin: 0 auto;
    overflow: scroll;
    
    &__titles{
        border-left: 1px solid $secondary-grey;
        border-right: 1px solid $secondary-grey;
        border-top: 1px solid $secondary-grey;
        border-bottom: 1px solid var(--grey-tint-light-2, #E7E7E7);
        background: var(--grey-tint-white, #FFF);
        background-color: $primary-green;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-span: 20;
        padding: 0 1rem;
        margin: 0 1rem;
        box-sizing: border-box;
        margin: 0 auto;
        color: $primary-white;
    }

    table{
        width: 100%;
        height: 7rem;
        margin: 0 auto;
        border-collapse: collapse;
        text-align: center;
    }

    td{
        border-left: 1px solid $secondary-grey;
        min-width: 1rem;
    }

    th{
        min-width: 2rem;
        border-left: 1px solid $secondary-grey;
    }

    &__hole{
        font-weight: 600;
    }

}

.group-row__1{
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
}

.group-row__2{
    background-color: $secondary-grey;
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
    border-bottom: 1px solid $primary-black;
}

.group-row__3{
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
    text-transform: capitalize;
}

.group-row__4{
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
    border-top: 1px solid $secondary-grey;
}

.group-row__5{
    background-color: $secondary-grey;
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
    border-bottom: 1px solid $primary-black;
}

.group-row__6{
    height: 2rem;
    border-left: 1px solid $secondary-grey;
    border-right: 1px solid $secondary-grey;
    text-transform: capitalize;
}

tr:last-of-type{
    border-bottom: 1px solid $secondary-grey;
}

@include desktop{
    .group-scorecard__container{
        padding: 1rem 4rem;
    }

    .group-scorecard{
        &__titles{
            margin-top: 3rem;
        }
    }
}
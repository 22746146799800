@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

$dark-green: #5a7161;
$light-gray: #f1f2ef;
$almost-black: #131c1e;
$grayish-green: #b5bcab;
$medium-green: #374845;
$olive-green: #798d77;

.make-group__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .make-group {
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    background: white;
    padding: 20px;
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0;

    &__header {
      margin-top: -10vh;
      height: 35vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('../../assets/images/putting-min.png');
      background-position: center;
      background-size: cover;

      &--title {
        color: #f1f2ef;
        letter-spacing: 1px;
        margin-top: 5rem;
        @include mobile-header-two;
      }
    }

    &__label,
    &__label-email {
      display: block;
      @include mobile-header-four;
      color: $almost-black;
      margin-bottom: 5px;
    }

    &__input,
    select {
      width: 100%;
      padding: 12px;
      border: 1px solid $grayish-green;
      border-radius: 5px;
      margin-bottom: 15px;
      box-sizing: border-box;
    }

    select {
      cursor: pointer;
    }

    .form__button,
    .add-email-button,
    .remove-email-button {
      width: 100%;
      background-color: $dark-green;
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-top: 10px;

      &:hover {
        background-color: $medium-green;
      }
    }

    .error-message {
      color: red;
      margin-top: 10px;
    }

    .success-message {
      color: green;
      margin-top: 10px;
    }

    .add-email-button,
    .remove-email-button {
      width: auto;
      background-color: $medium-green;
      margin-top: 5px;
    }
  }
}

// Friend Suggestions Dropdown
.friend-suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid $grayish-green;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;

  .friend-suggestion {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }
  }
}

// Selected Friends Section
.selected-friends {
  margin-top: 10px;

  .selected-friend {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid $grayish-green;

    button {
      background-color: $medium-green;
      border: none;
      color: white;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: $dark-green;
      }
    }
  }
}

// Media Queries for Responsiveness
@media screen and (max-width: 768px) {
  .make-group {
    padding: 15px;

    &__header {
      height: 25vh;

      &--title {
        margin-top: 3rem;
      }
    }

    .form__button,
    .add-email-button,
    .remove-email-button {
      padding: 8px;
      font-size: 0.9rem;
    }
  }

  .friend-suggestions {
    max-height: 150px;
  }
}


@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.friend-search {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__form {
        display: flex;
        gap: 1rem;
    }

    &__input {
        flex-grow: 1;
        padding: 0.5rem;
        border: 1px solid $Grayish-Green;
        border-radius: 0.25rem;
    }

    &__button {
        padding: 0.5rem 1rem;
        background-color: $primary-green;
        color: $primary-white;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;

        &:hover {
            background-color: $Grayish-Green;
        }
    }

    &__results {
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .friends--tile {
        background-color: $Light-Gray;
        border: 1px solid $Grayish-Green;
        border-radius: 0.5rem;
        display: flex;
        position: relative;
        padding: 1rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        &__img {
            border-radius: 50%;
            height: 60px;
            width: 60px;
            object-fit: cover;
            margin: auto 0;
            margin-right: 1rem;
            border: 2px solid $Olive-Green;
        }

        &__details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__buttons {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            display: flex;
            gap: 0.5rem;
        }
    }
}

.add-friend-button {
    background-color: $primary-green;
    border: 1px solid $primary-green;
    width: auto;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: $primary-white;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
        background-color: $Grayish-Green;
        border-color: $primary-black;
    }
}

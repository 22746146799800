@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.select-course__container {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/background2-min.png');
  background-size: cover;
  background-position: center;
  margin-top: -10vh;
}

.select-course {
  width: 100%;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 1rem;
  padding: 1rem; // Reduced padding for mobile screens
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh; // Ensure the container does not exceed viewport height
  overflow: hidden; // Hide overflow to prevent content from spilling out

  &__title {
    @include header-two;
    color: $secondary-white;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__heading{
    @include header-three;
    color: $primary-white;
    margin: 3rem auto;
  }

  &__content {
    width: 100%;
    flex: 1;
    overflow-y: auto; // Enable scrolling for the content area
  }

  &__section {
    width: 100%;
    margin-bottom: 1rem;
  }

  label {
    @include mobile-body-one;
    color: $primary-white;
    margin-bottom: 0.25rem;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../assets/icons/arrow_drop_down-24px.svg');
    background-position: right 1rem center;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.1);
    color: $primary-white;
    padding: 0.5rem 1rem;
    width: 100%;
    @include mobile-body-two;

    &:focus {
      outline: none;
      border-color: $Almost-Black;
    }
  }

  &__start {
    @include white-button;
    margin-top: 1rem;
    width: 100%;
    position: relative; // Position relative to the container
  }
}

.manual-select-button {
  @include secondary-button; // Use your existing mixin or define styles
  background-color: $Dark-Green;
  color: $primary-white;
  margin-top: 1rem;
  width: 100%;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.4);
  padding: 1rem;
  border-radius: 0.5rem;
  color: red;
  text-align: center;
  margin-bottom: 1rem;
}


/* Modal Styling */
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__front {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  width: 75%;
  max-width: 400px;
}

.modal__font {
  margin-bottom: 1.5rem;
  color: #000;
  font-size: 1rem;
}

.modal__button {
  color: #fff;
  background-color: $Almost-Black;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  margin: 0.5rem;
  cursor: pointer;
  @include mobile-body-two;

  &:hover {
    background-color: darken($Almost-Black, 10%);
  }
}

@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;


.profile-card{
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 1rem 1rem 1rem;
    margin: 0 auto;
    border-bottom: 3px solid $primary-black;
    background-image: url('../../assets/images/background3-min.png');
    background-position: center;
    background-size: cover;
    margin-top: -10vh;
}

.user{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 75%;
    border-radius: 0.5rem;
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(11.5px);
    box-sizing: border-box;

    &__image{
        border: 1px solid #000;
        width: 12vh;
        height: 12vh;
        border-radius: 50%;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        background-image: url('../../assets/icons/user-icon.jpeg');
        background-position: center;
        background-size: contain;
        border: 2px solid $primary-white;
    }

    &__name{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        @include mobile-header-three;
        color: #fff;
        line-height: normal;
    }

    &__first{
        margin-right: 0.5rem;
    }

    &__stats{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        text-align: center;
        @include mobile-header-three;
        color: #fff;
        line-height: normal;
        margin-bottom: 0;
    }

    &__buttons-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.5rem;
        margin: 0.5rem 0;
    
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &__start-round, &__start-tour {
        @include white-button;
        @include mobile-body-four;
        font-size: 0.65rem;
        padding: 0.3rem 0;
        width: 40%;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border: 2px solid $primary-white;
        margin: 0.2rem;
    
        @media (max-width: 768px) {
          width: 80%;
        }
    
        &:hover {
          cursor: pointer;
          background-color: $secondary-grey;
        }
      }

      &__start-tour {
        color: $secondary-white;
        margin-top: 0.5rem;
        background-color: transparent;
        border: 3px solid #fff;
    
        &:hover {
          background-color: $primary-purple;
          opacity: 0.9;
        }
      }
}

@include desktop{
    .profile-card{
        height: 60vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        padding: 1rem 4rem;
        margin: 0 auto;
        margin-top: -10vh;
    }

    .user__image{
        height: 12rem;
        width: 12rem;
        margin-right: 1rem;
        margin-left: 12rem;
    }

    .user{
        width: 40%;

        &__name{
            display: flex;
            justify-content: start;
            width: 100%;
            color: #fff;
            @include header-four;
            padding: 0 1rem;
        }

        &__stats{
            justify-content: space-between;
            color: #fff;
            @include header-four;
        }

        &__history{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            text-align: center;
            width: 40%;
            @include header-four;
        }

        &__location{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            @include header-four;
        }

        &__buttons-container{
            width: 100%;
            padding: 1rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        &__start-round{
            @include white-button;
            @include body-one;
            font-size: 0.75rem;
            width: 30%;
            transition: 0.2s;
            height: 2.5rem;

            a{
                text-decoration: none
            }

            &:hover{
                cursor: pointer;
                background-color: $secondary-grey;
            }
        }

        &__start-tour{
            @include white-button;
            @include body-one;
            color: #fff;
            background-color: transparent;
            border: 3px solid #fff;
            font-size: 0.75rem;
            width: 30%;
            transition: 0.2s;
            height: 2.5rem;

            a{
                text-decoration: none;
                color: $secondary-white;
            }

            &:hover{
                cursor: pointer;
                background-color: $Grayish-Green;
                color: $Almost-Black;
                opacity: 0.9;
                border: 3px solid $Almost-Black;
            }
        }

            
    }
}
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.activity-feed{
    margin: 1rem;
}

@include tablet {
    .activity-feed{
        margin: 1rem 2rem;
    }
}

@include desktop{
    .activity-feed{
        margin: 1rem 4rem;
    }
}


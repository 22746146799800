@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.tournament__card {
  border-radius: 0.75rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 400px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }

  &--header {
    box-sizing: border-box;
    width: 100%;
    height: 12vh;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px $Olive-Green solid;

    .header__icon {
      font-size: 2rem;
      color: $Almost-Black;
    }

    .status-badge {
      background-color: $Medium-Green;
      color: $Light-Gray;
      padding: 0.5rem 1rem;
      border-radius: 12px;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }

  &--title {
    @include mobile-header-one;
    color: $Almost-Black;
    margin: 1rem 0;
    text-align: center;
  }

  &--description {
    @include mobile-body-two;
    color: $Grayish-Green;
    margin: 0 1rem 1.5rem;
    text-align: center;
  }

  &--courses {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: 1rem 0;

    .course__item {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      border-bottom: 1px solid $Grayish-Green;

      &:last-child {
        border-bottom: none;
      }

      .course__icon {
        margin-right: 0.5rem;
        color: $Medium-Green;
      }
    }
  }

  &--button {
    width: 85%;
    padding: 0.75rem;
    margin: 1rem 0;
    background-color: $Olive-Green;
    color: $Light-Gray;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.85rem;
    letter-spacing: 1.5px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover {
      background-color: $Almost-Black;
      color: $Light-Gray;
    }
  }
}

@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.leaderboard{
    padding: 0 1rem;
    margin-bottom: 2rem;
    

    &__subheader{
        width: 100%;
        border-bottom: 2px solid #000;
    }

    &__column-filter{
        background-color: $secondary-grey;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-left: 1px solid $secondary-grey;
        border-right: 1px solid $secondary-grey;
        border-top: 1px solid $secondary-grey;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        @include mobile-header-two;
    }

    &__rows{
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-left: 1px solid $secondary-grey;
        border-right: 1px solid $secondary-grey;

        &:last-child{
            border-bottom: 1px solid $secondary-grey;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }

        &__position,
            &__column-position{
            width: 10%;
            text-align: center;
        }

        &__name,
            &__column-name{
                width: 50%;
                text-align: start;
            }
        
        &__total,
            &__column-total{
                width: 20%;
                text-align: center;
            }
        
        &__ptsoff,
            &__column-ptsoff{
                width: 20%;
                text-align: center;
            }

}

@include desktop{
    .leaderboard{
        padding: 0 4rem;
    }
}
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
}

.pagination-controls {
  margin: 1rem auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.scorecard {
  position: relative; // Add relative positioning to the scorecard

  &__titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $Olive-Green;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: none;
    color: $secondary-white;
    padding: 0 1rem;
    letter-spacing: 1px;
  }

  &__date-wrapper {
    margin: 0.75rem 0;
    margin-left: auto;
  }

  &__date {
    margin: 0;
    @include mobile-header-four;
    color: $primary-white;
    letter-spacing: 1px;
    line-height: 1.5;
    border-radius: 0.5rem;
    text-align: end; // Optional: to add a bit of styling
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__delete {
    margin: 0 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__delete-icon {
    width: 1rem;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid $secondary-grey;
    text-align: center;
    width: 43px; // Fixed width
    height: 43px; // Fixed height
    font-size: 0.85rem;
  }

  th {
    background-color: $primary-black;
    color: $primary-white;
  }

  .scorecard__hole {
    background-color: $primary-grey;
  }

  .scorecard__par,
  .scorecard__score {
    font-size: 0.85rem;
    background-color: $secondary-grey;
    border: 1px solid $primary-grey;
  }

  .score-par {
    border: 0.75px solid #000;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .score-less-than-par {
    border-radius: 50%;
    border: 0.75px solid #000;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .score-more-than-par {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.25rem;
    text-align: center;
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__front {
  background-color: $primary-white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal__font {
  margin-bottom: 1rem;
  color: #000;
}

.modal__button {
  color: $primary-white;
  background-color: #000;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
}

.modal__button:hover {
  background-color: #d3d3d3;
}

.loading {
  text-align: center;
  padding: 2rem;

  &__title {
    margin-bottom: 1rem;
  }

  button {
    color: $primary-white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: $Dark-Green;
    }

    a {
      text-decoration: none;
      color: $primary-white;
    }
  }
}

.advanced__stats {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;

  &--data {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 4rem; /* Adjusted size */
  }

  svg {
    margin-bottom: 0.5rem;
    animation: stroke-animation 1.5s ease-in-out;
  }

  .advanced__stats--label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    font-size: 0.85rem;
    color: #131c1e;
  }
}

@keyframes stroke-animation {
  from {
    stroke-dasharray: 0, 100;
  }
  to {
    stroke-dasharray: var(--percentage), 100;
  }
}

@include desktop {
  .container {
    margin: 0 auto;
    margin-top: -2rem;
  }

  .scorecard {
    width: 80%;
    margin: 2rem auto;
  }

  .modal__font {
    @include mobile-header-two;
    margin: 1rem 4rem;
  }

  .modal__button {
    @include white-button;
    width: 40%;
    font-size: 0.75rem;
  }
}

@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.user__statistics {
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid $Grayish-Green;
    margin-bottom: 3rem;
    background-color: $Light-Gray;

    &--title {
        color: $Almost-Black;
        text-align: center;
        letter-spacing: 1px;
        @include mobile-header-one;
    }

    &--container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        max-width: 900px;
        margin-top: 1rem;
    }
}

.stat {
    &__card {
        background-color: $Light-Gray;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        width: 100%;
        max-width: 300px;
        transition: transform 0.2s;

        &:hover {
            transform: translateY(-5px);
        }
    }

    &__icon {
        color: $Medium-Green;
        font-size: 3rem;
        flex-shrink: 0;
        margin-right: 1rem;
    }

    &__values--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__label {
        @include mobile-header-three;
        color: $Almost-Black;
        margin-bottom: 0.25rem;
    }

    &__value {
        color: $Dark-Green;
        font-size: 1.5rem;
    }
}

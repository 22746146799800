

// Breakpoint variables and sizes for devices
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;


// Colors for site variables
$secondary-purple: #673AB7;
$primary-purple: #C6A7FF;

$primary-green: #3E6A35;
$secondary-green: #BFED7B;

$primary-black: #131513;
$secondary-black: #505350;

$primary-grey:#ACACAC;
$secondary-grey: #E7E7E7;

$primary-white: #FAFAFA;
$secondary-white: #FFFFFF;


// new color palette
$Dark-Green: #5a7161;
$Light-Gray: #f1f2ef;
$Almost-Black: #131c1e;
$Grayish-Green: #b5bcab;
$Medium-Green: #374845;
$Olive-Green: #798d77;

$metallic-gold: #d4af37;
$metallic-gold-light: #f9e7b6;
$metallic-gold-dark: #a67c00;

$metallic-gold-gradient: linear-gradient(
  145deg,
  $metallic-gold-light 0%,
  $metallic-gold 50%,
  $metallic-gold-dark 100%
);
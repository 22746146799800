@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.edit{
    width: 100%;
    box-sizing: border-box;
    margin: 2rem auto;
    
    &__title{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin: 0;
        margin-top: -7rem;
        color: $primary-green;
        background-image: url('../../assets/images/background7-min.jpg');
        background-position: center;
        background-size: cover;
        height: 60vw;
        color: $primary-white;

    }
}

.edit-form{
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    &__title{
        @include mobile-header-three;
        padding-top: 0.75rem;
        border-top: 1px solid $primary-purple;
        width: 100%;
    }

    &__image{
        width: 100%;
        box-sizing: border-box;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        &__column{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__new-image{
            height: 6rem;
            width: 6rem;
            margin-bottom: 1rem;
            border: 1px solid $primary-grey;
            border-radius: 50%;
        }

        &__column{
            @include white-button;
            border: 1px solid $secondary-grey;

            label{
                cursor: pointer;
            }
        }

        &__upload{
           display: none;
        }

    &__location{
        width: 100%;
    }

    &__password{
        width: 100%;
    }

    &__account{
        width: 100%;
    }

    &__section{
        width: 100%;
        margin: 1rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__label{
        @include mobile-header-four;
    }

    &__input{
        @include input-field;
        width: 70%;
        border: 1px solid $secondary-grey;
        @include mobile-body-two;
    }

    &__update{
        @include white-button-mobile;
        border: 1px solid $primary-grey;
        font-size: 0.75rem;
        margin: 1rem auto;
    }
}



@include desktop {

    .edit{
        box-sizing: border-box;
        border-radius: 1rem;
        margin: 1rem auto;

        &__title{
            height: 35vw;
        }

        &-form{
            padding: 2rem 4rem;
        }

        &-form__new-image{
            height: 10rem;
            width: 10rem;
        }
    }
}
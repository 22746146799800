@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

.background{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(0deg, rgba(62, 106, 53, 0.30) 0%, rgba(62, 106, 53, 0.30) 100%), url('../../assets/images/background4.png'), lightgray 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    box-sizing: border-box;
 }
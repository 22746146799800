@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.tournament-details__container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.tournament-details__title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.tournament-progress__container, 
.tournament-leaderboard__container {
  margin: 20px 0;
}

.tournament-leaderboard__container table {
  width: 100%;
  border-collapse: collapse;
}

.tournament-leaderboard__container th, 
.tournament-leaderboard__container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tournament-leaderboard__container th {
  background-color: #5a7161;
  color: #fff;
}

.tournament-progress__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tournament-progress__progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.tournament-progress__progress {
  height: 10px;
  background-color: #5a7161;
  border-radius: 10px;
}

.completed {
  color: green;
}

.pending {
  color: red;
}

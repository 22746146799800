@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.tournaments__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -10vh;

    &--header{
      width: 100%;
      height: 35vh;
      margin:0 auto;
      background-image: url('../../assets/images/golf-tournament-header.jpg');
      background-position: center;
      background-size: cover;
    }
  
    &--title {
      width: 100%;
      text-align: left;
      margin: 0 1rem;
      margin-top: 25vh;
      color: #f1f2ef;
    }
  
    .tournaments__search {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
      margin: 1rem;
  
      input {
        width: 80%;
        padding: 0.75rem;
        border: 1px solid #798d77;
        border-radius: 8px;
        font-size: 1rem;
        color: #374845;
        background-color: #f1f2ef;
      }
    }
  }
  


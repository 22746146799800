@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.courses {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  &__filter {
    box-sizing: border-box;
    border: 1px solid $primary-grey;
    border-radius: 1rem;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 2rem auto;
  }

  &__tiles {
    width: 90%;
    display: flex;
    flex-wrap: wrap; // Enable wrapping
    justify-content: center; // Center the tiles
    margin: 1rem auto;
  }

  &__tile {
    border: 1px solid $primary-grey;
    border-radius: 0.75rem;
    width: 100%;
    max-width: 320px;
    height: auto; /* Adjusted to fit content */
    margin: 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensure content does not overflow */

    // Adjust the width for tablets and larger screens
    @media (min-width: 768px) {
      width: calc(50% - 2rem); // Two tiles per row
    }

    @media (min-width: 1024px) {
      width: calc(33.33% - 2rem); // Three tiles per row
    }
  }

  &__image {
    width: 100%;
    height: 35vh;
    border: none;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    object-fit: cover; /* Ensure the image fits within the tile */
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 0 1rem;
  }

  &__title {
    @include mobile-header-three;
    line-height: normal;
    margin: 0.5rem 0;
    height: 3rem;
  }

  &__location {
    @include mobile-header-five;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    flex-grow: 1; /* Ensure it takes up remaining space */
  }

  &__holes {
    text-align: left;
    @include mobile-body-two;
  }

  &__prices {
    text-align: left;
    @include mobile-body-two;
    margin: 0.5rem 0; /* Ensure there's space around the prices */
    line-height: 1.5; /* Make sure prices have enough line height */
    overflow: hidden; /* Prevent overflow */
  }

  &__book {
    align-self: flex-end; /* Ensure the button is at the end */
    @include secondary-button-mobile;
    border: 1px solid $primary-grey;
    border-radius: 1rem;
    transition: 0.25s;
    background-color: $primary-green;

    &:hover {
      background-color: $primary-purple;
    }

    a {
      color: $primary-white;
      text-decoration: none;
    }
  }
}

.filter {
  &__title {
    width: 100%;
    text-align: center;
    line-height: normal;
    margin: 0.75rem auto;
  }
  
  &__section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid $primary-grey;
    width: 80%;
  }
  
  &__label {
    @include mobile-body-two;
    margin-bottom: 0.75rem;
  }
  
  &__select {
    max-width: 10rem;
    border: 1px solid $primary-grey;
    border-radius: 0.25rem;
    padding: 0.25rem;
    width: 100%;
    text-align: center;
    margin-bottom: 0.75rem;
  }
  
  &__button-reset {
    @include secondary-button;
    margin: 0.75rem auto;
    font-size: 0.75rem;
    transition: 0.2s;
    width: 85%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &:hover {
      background-color: $primary-purple;
      color: $primary-white;
      border: $primary-grey 1px solid;
    }
  }
}

.courses__tile {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
}

@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.tours{
    box-sizing: border-box;
    width: 100vw;
    margin: 1rem auto;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
    margin: 0 auto;

    &__tile{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;
        overflow: hidden;
        box-sizing: border-box;
        border-right: 1px solid $primary-black;
        border-bottom: 1px solid $primary-black;
        transition: transform 0.3s ease-in-out;
        text-decoration: none;
        color: #333;
        margin: 0.5rem 1rem;
        padding: 1rem 2rem;
        

        &:hover{
            transform: translateY(-5px);
        }
    }

}

.tour__name{
    border-bottom: 1px solid $primary-grey;
    width: 100%;
    text-align: center;
    color: $primary-black;
}

.tour__description{
    text-align: center;
}

.tour__button{
    @include white-button-mobile;
    padding: 0rem 1rem;
    border: 1px solid #000;
    width: 100%;
    margin: 0.75rem auto;
}


@include desktop {
    .tours__tile{
        width: 25%;
        height: 17rem;
        color: $primary-black;
    }
}
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    &__circle {
      border: 5px solid rgba(0, 0, 0, 0.1);
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
  
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
  
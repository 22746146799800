@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.input__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
}

.header__image {
  height: 50vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url('../../assets/images/background3-min.png');
  background-position: center;
  background-size: cover;
  margin-top: -10rem;
}

.score {
  max-width: 375px;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  margin-top: -8rem;

  &__info {
    box-sizing: border-box;
    width: 100%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    text-align: center;
    background-color: $primary-green;
    border: none;
    border-radius: 1rem;
    border-left: #DCD5B9 1px solid;
    border-top: #DCD5B9 1px solid;
    border-right: #DCD5B9 1px solid;
  }

  &__course {
    @include mobile-header-four;
    color: $primary-white;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__results {
    width: 80%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__total {
    color: $primary-white;
    @include mobile-header-three;
  }

  &__result {
    color: $primary-white;
    @include mobile-header-three;
    margin-right: 0.5rem;
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
  }

  &__hole {
    width: 100%;
    @include header-two;
    color: $primary-white;
  }

  &__nums {
    width: 80%;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include mobile-body-one;
    color: $secondary-white;
  }

  &__par {
    width: 100%;
    margin: 0;
    text-decoration: underline 2px $primary-white;
  }

  &__distance {
    width: 100%;
    margin: 0;
    text-decoration: underline 2px $primary-white;
  }

  &__tracker {
    width: 100%;
    height: 40%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  input[type=number] {
    text-align: center;
  }

  &__decrement,
  &__increment {
    border-radius: 50%;
    border: 1px solid #d3d3d3;
    height: 3.25rem;
    width: 3.25rem;
    background-color: $Olive-Green;
    color: $primary-white;
    font-size: 1.5rem;
    margin: 0.5rem;
    transition: 0.2s ease-in;
    cursor: pointer;

    &:active {
      background-color: #000;
      color: #fff;
    }
  }

  &__input {
    padding: 0.75rem 1rem;
    width: 3.25rem;
    height: 5rem;
    @include body-one;
  }

  &__control {
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__view {
    margin-bottom: 1rem;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__skip {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  &__previous,
  &__next {
    @include secondary-button;
  }

  &__stats {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;

    .score__stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.5rem;

      .score__stat-label {
        color: #000;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__front {
  background-color: $primary-white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal__font {
  margin-bottom: 1rem;
  color: #000;
}

.modal__button {
  color: $primary-white;
  background-color: #000;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #d3d3d3;
  }
}

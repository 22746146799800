@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;

.friends--section{
    margin: 1rem;

    &__header{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        justify-content: space-between;
    }

    &__button{
        @include white-button-mobile;
        width: fit-content;
    }

    &__link{
        color: $Light-Gray;
        text-decoration: none;
        @include mobile-button-text;
    }
}

@include tablet{
    .friends--section{
        max-width: 75%;
        margin: 1.25rem auto;
    }
}

@include desktop{
    .friends--section{
        max-width: 50%;
        margin: 1.25rem auto;
    }
}
@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/typograghy' as *;

.friends {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--tile {
        background-color: $Light-Gray;
        border: 1px solid $Grayish-Green;
        border-radius: 0.5rem;
        display: flex;
        position: relative; // Allows buttons to be positioned absolutely
        padding: 1rem;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        @include box-shadow;

        &__img {
            border-radius: 50%;
            height: 60px;
            width: 60px;
            object-fit: cover;
            margin: auto 0;
            margin-right: 1rem;
            border: 2px solid $Olive-Green;
        }

        &__details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__name-location {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
        }

        &__buttons {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            display: flex;
            gap: 0.5rem;
        }
    }

    &--name {
        display: flex;
        gap: 0.25rem;

        &__first,
        &__last {
            @include header-five;
            color: $Dark-Green;
        }
    }

    &--location {
        display: flex;
        gap: 0.25rem;
        @include mobile-body-three;
        color: $Medium-Green;

        &__city,
        &__province {
            @include body-three;
            font-size: 0.75rem;
        }
    }

    &--button__accept,
    &--button__deny {
        width: auto;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: $primary-white;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    &--button__accept {
        background-color: $primary-green;
        border: 1px solid $primary-green;

        &:hover {
            background-color: $Grayish-Green;
            border-color: $primary-black;
        }
    }

    &--button__deny {
        background-color: $primary-black;
        border: 1px solid $primary-black;

        &:hover {
            background-color: $secondary-black;
            border-color: $secondary-black;
        }
    }
}

// Add media queries for larger screens
@include tablet {
    .friends--tile {
        flex-direction: row;
        align-items: center;

        &__img {
            height: 80px;
            width: 80px;
        }

        &__details {
            text-align: left;
        }

        &__name-location {
            flex-direction: column;
        }

        &__buttons {
            bottom: 1.5rem; // Adjust position for larger screens if needed
            right: 1.5rem;
        }
    }
}
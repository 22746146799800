@use '../../styles/partials/mixins.scss' as *;
@use '../../styles/partials/variables.scss' as *;

.activity {
    margin-bottom: 1.5rem;

    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0; /* Adjust padding for spacing */
    }

    &__tile {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 1.5rem;
        border-radius: 12px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__tile--user {
        display: flex;
        align-items: center;
    }

    &__img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 1rem;
    }

    &__user {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
    }

    &__user--name {
        color: #5a7161;
        font-size: 1.2rem;
    }

    &__user--date {
        color: #b5bcab;
        font-size: 0.9rem;
    }

    &__tile--stats {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        border-top: #374845 1px solid;
        border-bottom: #374845 1px solid;
    }

    &__stats {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        color: #f1f2ef;
        text-align: center;
    }

    &__stats--title {
        font-weight: bold;
        font-size: 0.90rem;
        margin-bottom: 0.25rem;
        color: $primary-black;
        text-decoration: underline solid #000;
    }

    &__stats--score {
        font-size: 0.90rem;
        font-weight: bold;
        color: $secondary-black;
    }

    &__tile--course {
        color: #798d77;
        text-align: left;
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    /* Full-width for the toggle buttons like 'View Scorecard' */
    &__toggle-button{
        background-color: #5a7161;
        color: white;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%; /* Full-width button */
        text-align: center;

        &:hover {
            background-color: #374845;
        }
    }

    td{
        text-align: center;
    }

    &__toggle-comments-button {
        background-color: #5a7161;
        color: white;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 50%; /* Full-width button */
        text-align: center;
        height: 40px;

        &:hover {
            background-color: #374845;
        }
    }

    /* Adjust like and comment buttons to be inline with the same height */
    .activity__actions {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: space-between;
    }

    /* Like and comment buttons */
    .activity__like-button, .activity__comment-button {
        background-color: #5a7161;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        cursor: pointer;
        height: 40px; /* Ensure both buttons have the same height */
        width: 35%; /* Each button takes half of the space */
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #374845;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: #798d77; /* Disabled state color */
        }
    }

    .activity__comments-section {
        background-color: #f1f2ef;
        padding: 1rem;
        border-radius: 10px;
        margin-top: 1rem;

        h4 {
            color: #5a7161;
        }

        .activity__comments-list {
            list-style: none;
            padding: 0;
            margin-bottom: 1rem;

            .activity__comment {
                margin-bottom: 0.5rem;
                color: #b5bcab;

                strong {
                    color: #5a7161;
                }
            }
        }

        .activity__add-comment {
            display: flex;
            align-items: center;

            input {
                flex: 1;
                padding: 0.5rem;
                border-radius: 5px;
                border: 1px solid #5a7161;
                margin-right: 0.5rem;
            }

            button {
                background-color: #5a7161;
                color: white;
                border: none;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                cursor: pointer;


                &:hover {
                    background-color: #374845;
                }
            }
        }
    }
}


@include tablet {
    .activity{
        margin: 1rem 2rem;
    }
}

@include desktop{
    .activity{
        margin: 1rem 4rem;

        &__tile{
            margin: 2rem auto;
            max-width: 650px;
        }
    }
}
@use '../../styles/partials/typograghy' as *;
@use '../../styles/partials/variables.scss' as *;
@use '../../styles/partials/mixins.scss' as *;


.tours__container{
    width: 100%;
    height: 100vh;
    margin-top: -10vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
    box-sizing: border-box;
}

.tours__header{
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @include mobile-header-three;
    color: $primary-white;
    background-image: url('../../assets/images/background5-min.jpeg');
    background-position: center;
    background-size: cover;
}

.tours__titles{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.tours__title{
    padding: 1.5rem;
    text-decoration: underline $Dark-Green 3px;
}

.tours__link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-size: 1rem;
    background-color: $Grayish-Green;
    border: 2px solid $Light-Gray;
    color: $Almost-Black;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
}

.tours__tiles-row{
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
    height: 100%;
    margin: 1rem auto;
}

@include tablet{
    .tours__container{
        margin: 0 auto;
        padding: 0;
        margin-top: -10vh;
    }

    .tours__header{
        height: 50vh;
        flex-direction: row;
        justify-content: space-around;
        @include header-four;
        color: $primary-white;
        }

    .tours__tile{
        @include body-three;
        color: $primary-black; 
    }
}